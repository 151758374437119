import useSwal from "@common-hooks/useSwal"
import {
     deleteDemandeEmbauche,
     demandeRectifParDirectionDemandeEmbauche,
     demandeRectifParResponsableDemandeEmbauche,
     demandeRectifParSiegeDemandeEmbauche,
     rejetDefinitifParDirectionDemandeEmbauche,
     rejetDefinitifParResponsableDemandeEmbauche,
     soumissionDemandeEmbauche,
     validationDirectionDemandeEmbauche,
     validationRespDemandeEmbauche,
     validationSiegeDemandeEmbauche,
} from "../_documents/core/_requests"
import { toast } from "react-toastify"
import Swal from "sweetalert2"
import { IConsultantDEsuiviModel, IConsultantModel } from "@common-models/*"
import { useAuth } from "../../../../../AppContext"
import { CONST_CONSULTANT_CONTRAT_ST, CONST_DEFAULT_TOAST_OPTIONS } from "@common-constants/*"
import axios from "axios"
import { CONST_API_BUSINESS_CONSULTANT_GET_DE_DOWNLOAD_DOCS_ENDPOINT } from "../../../../../endpoints"
import FileAndDriveHelper from "@common-helpers/FileAndDriveHelper"

export const useDemandeEmbaucheActions = (consultant_id: number) => {
     const { swal } = useSwal()
     const { currentUser } = useAuth()

     function soumission(callback: (suivi: IConsultantDEsuiviModel) => void = () => {}) {
          swal.fire({
               icon: "warning",
               title: `Vous êtes sur le point de soumettre la demande d'embauche`,
               text: `Voulez-vous finaliser?`,
               input: "textarea",
               inputPlaceholder: "Commentaire (optionnel)",
               showCancelButton: true,
               confirmButtonText: "Confirmer",
               cancelButtonText: "Annuler",
               showLoaderOnConfirm: true,
               preConfirm: async (commentaire: string) => {
                    return soumissionDemandeEmbauche(consultant_id, commentaire)
                         .then(r => {
                              toast.success("Votre demande d'embauche a bien été envoyée.")
                              callback(r.data)
                         })
                         .catch(e => {
                              toast.error(e?.response?.data?.detail, { autoClose: false })
                         })
               },
               allowOutsideClick: () => !Swal.isLoading(),
          }).then()
     }

     function demandeRectifParResponsable(callback: (suivi: IConsultantDEsuiviModel) => void = () => {}) {
          swal.fire({
               icon: "warning",
               input: "textarea",
               text: "Une demande de rectification sera envoyée avec votre commentaire",
               inputPlaceholder: "Commentaire",
               inputAttributes: {
                    required: "true",
               },
               showCancelButton: true,
               confirmButtonText: "Confirmer",
               cancelButtonText: "Annuler",
               showLoaderOnConfirm: true,
               inputValidator: value => {
                    return new Promise((resolve: any) => {
                         value === "" ? resolve("Merci d'ajouter un commentaire") : resolve()
                    })
               },
               preConfirm: (commentaire: string) => {
                    return demandeRectifParResponsableDemandeEmbauche(consultant_id, commentaire)
                         .then(r => {
                              toast.success("Votre demande de rectification a bien été envoyée.")
                              callback(r.data)
                         })
                         .catch(e => {
                              toast.error(e?.response?.data?.detail, { autoClose: false })
                         })
               },
               allowOutsideClick: () => !Swal.isLoading(),
          }).then()
     }

     function validationResp(callback: (suivi: IConsultantDEsuiviModel) => void = () => {}) {
          swal.fire({
               icon: "warning",
               title: `Vous êtes sur le point de valider cette embauche`,
               text: `Voulez-vous continuer?`,
               input: "textarea",
               inputPlaceholder: "Commentaire (optionnel)",
               showCancelButton: true,
               confirmButtonText: "Confirmer",
               cancelButtonText: "Annuler",
               showLoaderOnConfirm: true,
               preConfirm: (commentaire: string) => {
                    return validationRespDemandeEmbauche(consultant_id, commentaire)
                         .then(r => {
                              toast.success("Vous avez bien validé la demande d'embauche")
                              callback(r.data)
                         })
                         .catch(e => {
                              toast.error(e?.response?.data?.detail, { autoClose: false })
                         })
               },
               allowOutsideClick: () => !Swal.isLoading(),
          }).then()
     }

     function demandeRectifParDirection(callback: (suivi: IConsultantDEsuiviModel) => void = () => {}) {
          swal.fire({
               icon: "warning",
               input: "textarea",
               text: "Une demande de rectification sera envoyée avec votre commentaire",
               inputPlaceholder: "Commentaire",
               inputAttributes: {
                    required: "true",
               },
               showCancelButton: true,
               confirmButtonText: "Confirmer",
               cancelButtonText: "Annuler",
               showLoaderOnConfirm: true,
               inputValidator: value => {
                    return new Promise((resolve: any) => {
                         value === "" ? resolve("Merci d'ajouter un commentaire") : resolve()
                    })
               },
               preConfirm: (commentaire: string) => {
                    return demandeRectifParDirectionDemandeEmbauche(consultant_id, commentaire)
                         .then(r => {
                              toast.success("Votre demande de rectification a bien été envoyée.")
                              callback(r.data)
                         })
                         .catch(e => {
                              toast.error(e?.response?.data?.detail, { autoClose: false })
                         })
               },
               allowOutsideClick: () => !Swal.isLoading(),
          }).then()
     }

     function validationDirection(callback: (suivi: IConsultantDEsuiviModel) => void = () => {}) {
          swal.fire({
               icon: "warning",
               title: `Vous êtes sur le point de valider cette embauche`,
               text: `Voulez-vous continuer?`,
               input: "textarea",
               inputPlaceholder: "Commentaire (optionnel)",
               showCancelButton: true,
               confirmButtonText: "Confirmer",
               cancelButtonText: "Annuler",
               showLoaderOnConfirm: true,
               preConfirm: (commentaire: string) => {
                    return validationDirectionDemandeEmbauche(consultant_id, commentaire)
                         .then(r => {
                              toast.success("Vous avez bien validé la demande d'embauche")
                              callback(r.data)
                         })
                         .catch(e => {
                              toast.error(e?.response?.data?.detail, { autoClose: false })
                         })
               },
               allowOutsideClick: () => !Swal.isLoading(),
          }).then()
     }

     function validationSiege(callback: (suivi: IConsultantDEsuiviModel) => void = () => {}) {
          swal.fire({
               icon: "warning",
               title: `Vous êtes sur le point de valider cette embauche`,
               text: `Voulez-vous continuer?`,
               input: "textarea",
               inputPlaceholder: "Commentaire (optionnel)",
               showCancelButton: true,
               confirmButtonText: "Confirmer",
               cancelButtonText: "Annuler",
               showLoaderOnConfirm: true,
               preConfirm: async (commentaire: string) => {
                    return validationSiegeDemandeEmbauche(consultant_id, commentaire)
                         .then(r => {
                              toast.success("Le consultant a bien été intégré parmi les effectifs.")
                              callback(r.data)
                              if (r.data.consultant?.contrat !== CONST_CONSULTANT_CONTRAT_ST) {
                                   toast.info(
                                        "Le salarié sera automatiquement associé à sa fiche existante sur CEGEDIM. Assurez-vous que le nom et prénom sont correctement saisis.",
                                        { autoClose: false }
                                   )
                              }
                         })
                         .catch(e => {
                              toast.error(e?.response?.data?.detail, { autoClose: false })
                         })
               },
               allowOutsideClick: () => !Swal.isLoading(),
          }).then()
     }

     function demandeRectifParSiege(callback: (suivi: IConsultantDEsuiviModel) => void = () => {}) {
          swal.fire({
               icon: "warning",
               input: "textarea",
               text: "Une demande de rectification sera envoyée avec votre commentaire",
               inputPlaceholder: "Commentaire",
               inputAttributes: {
                    required: "true",
               },
               showCancelButton: true,
               confirmButtonText: "Confirmer",
               cancelButtonText: "Annuler",
               showLoaderOnConfirm: true,
               inputValidator: value => {
                    return new Promise((resolve: any) => {
                         value === "" ? resolve("Merci d'ajouter un commentaire") : resolve()
                    })
               },
               preConfirm: (commentaire: string) => {
                    return demandeRectifParSiegeDemandeEmbauche(consultant_id, commentaire)
                         .then(r => {
                              toast.success("Votre demande de rectification a bien été envoyée.")
                              callback(r.data)
                         })
                         .catch(e => {
                              toast.error(e?.response?.data?.detail, { autoClose: false })
                         })
               },
               allowOutsideClick: () => !Swal.isLoading(),
          }).then()
     }

     function rejetDefinitifParDirection(callback: (suivi: IConsultantDEsuiviModel) => void = () => {}) {
          swal.fire({
               icon: "warning",
               input: "textarea",
               title: `Êtes-vous sûr${currentUser?.civilite === "CIVILITE_MME" ? "e" : ""} de vouloir rejeter définitifivement cette demande?`,
               text: "Cette action est irréversible",
               inputPlaceholder: "Commentaire",
               inputAttributes: {
                    required: "true",
               },
               showCancelButton: true,
               confirmButtonText: "Confirmer",
               cancelButtonText: "Annuler",
               showLoaderOnConfirm: true,
               inputValidator: value => {
                    return new Promise((resolve: any) => {
                         value === "" ? resolve("Merci d'ajouter un commentaire") : resolve()
                    })
               },
               preConfirm: (commentaire: string) => {
                    return rejetDefinitifParDirectionDemandeEmbauche(consultant_id, commentaire)
                         .then(r => {
                              toast.info("La demande a été rejetée.")
                              callback(r.data)
                         })
                         .catch(e => {
                              toast.error(e?.response?.data?.detail, { autoClose: false })
                         })
               },
               allowOutsideClick: () => !Swal.isLoading(),
          }).then()
     }

     function rejetDefinitifParResponsable(callback: (suivi: IConsultantDEsuiviModel) => void = () => {}) {
          swal.fire({
               icon: "warning",
               input: "textarea",
               title: `Êtes-vous sûr${currentUser?.civilite === "CIVILITE_MME" ? "e" : ""} de vouloir rejeter définitifivement cette demande?`,
               text: "Cette action est irréversible",
               inputPlaceholder: "Commentaire",
               inputAttributes: {
                    required: "true",
               },
               showCancelButton: true,
               confirmButtonText: "Confirmer",
               cancelButtonText: "Annuler",
               showLoaderOnConfirm: true,
               inputValidator: value => {
                    return new Promise((resolve: any) => {
                         value === "" ? resolve("Merci d'ajouter un commentaire") : resolve()
                    })
               },
               preConfirm: async (commentaire: string) => {
                    return rejetDefinitifParResponsableDemandeEmbauche(consultant_id, commentaire)
                         .then(r => {
                              toast.info("La demande a été rejetée.")
                              callback(r.data)
                         })
                         .catch(e => {
                              toast.error(e?.response?.data?.detail, { autoClose: false })
                         })
               },
               allowOutsideClick: () => !Swal.isLoading(),
          }).then()
     }

     function deleteDemande(callback: (suivi: IConsultantDEsuiviModel) => void = () => {}) {
          swal.fire({
               icon: "warning",
               input: "textarea",
               title: `Êtes-vous sûr${currentUser?.civilite === "CIVILITE_MME" ? "e" : ""} supprimer cette demande?`,
               text: "Cette action est irréversible",
               inputPlaceholder: "Commentaire",
               inputAttributes: {
                    required: "true",
               },
               showCancelButton: true,
               confirmButtonText: "Confirmer",
               cancelButtonText: "Annuler",
               showLoaderOnConfirm: true,
               inputValidator: value => {
                    return new Promise((resolve: any) => {
                         value === "" ? resolve("Merci d'ajouter un commentaire") : resolve()
                    })
               },
               preConfirm: async (commentaire: string) => {
                    return deleteDemandeEmbauche(consultant_id, commentaire)
                         .then(() => {
                              toast.info("La demande a bien été supprimée.")
                              callback({})
                         })
                         .catch(e => {
                              toast.error(e?.response?.data?.detail, { autoClose: false })
                         })
               },
               allowOutsideClick: () => !Swal.isLoading(),
          }).then()
     }

     function downloadDocs(consultant: IConsultantModel) {
          toast.info("Téléchargement en cours ...", {
               ...CONST_DEFAULT_TOAST_OPTIONS,
               autoClose: false,
          })
          axios.get(CONST_API_BUSINESS_CONSULTANT_GET_DE_DOWNLOAD_DOCS_ENDPOINT(consultant_id), {
               responseType: "blob",
          })
               .then(r => {
                    const fileName = `documents_embauche_${consultant.prenom}_${consultant.nom}.zip`
                    FileAndDriveHelper.downloadFile(r.data, fileName)

                    toast.dismiss()
                    toast.success("Les documents ont bien été téléchargées sous Zip")
               })
               .catch(() => {
                    toast.dismiss()
                    toast.error("Un problème est survenu lors du téléchargement des documents.", { autoClose: false })
               })
     }

     return {
          soumission,
          demandeRectifParResponsable,
          validationResp,
          demandeRectifParDirection,
          validationDirection,
          demandeRectifParSiege,
          rejetDefinitifParDirection,
          rejetDefinitifParResponsable,
          deleteDemande,
          downloadDocs,
          validationSiege,
     }
}
